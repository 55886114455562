var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item != null)?_c('div',[_c('validation-observer',{ref:"form"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('info-card',{attrs:{"has-been-edited":_vm.hasBeenEdited,"editing-item":_vm.editingItem,"item":_vm.editingItem,"is-editing":_vm.editing},on:{"refetch":_vm.getItem,"toggleEditing":_vm.toggleEditing,"saveChanges":_vm.saveChanges}})],1)],1),_c('b-tabs',{attrs:{"pills":""}},[_c('b-tab',{key:"tab-scopes",attrs:{"title":"General"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('validation-observer',{ref:"form"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Name"}},[_c('validation-provider',{attrs:{"name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"readonly":!_vm.editing,"state":errors.length > 0 ? false:null},model:{value:(_vm.editingItem.name),callback:function ($$v) {_vm.$set(_vm.editingItem, "name", $$v)},expression:"editingItem.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1896001931)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Dynamics Instance Domain"}},[_c('validation-provider',{attrs:{"name":"Dynamics Instance Domain"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"readonly":!_vm.editing,"state":errors.length > 0 ? false:null},model:{value:(_vm.editingItem.domain),callback:function ($$v) {_vm.$set(_vm.editingItem, "domain", $$v)},expression:"editingItem.domain"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2969070412)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Dynamics Api Key"}},[_c('validation-provider',{attrs:{"name":"Dynamics Api Key"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"readonly":!_vm.editing,"state":errors.length > 0 ? false:null},model:{value:(_vm.editingItem.key),callback:function ($$v) {_vm.$set(_vm.editingItem, "key", $$v)},expression:"editingItem.key"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,552943963)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Division Codes"}},[_c('validation-provider',{attrs:{"name":"Division Code List"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-tags',{attrs:{"disabled":!_vm.editing,"placeholder":"Enabled Division Codes"},model:{value:(_vm.editingItem.divisions),callback:function ($$v) {_vm.$set(_vm.editingItem, "divisions", $$v)},expression:"editingItem.divisions"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4123374949)})],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }